<template>
  <div id="main-rem">
    <div id="center-dis" v-on:click="loveu()" class="animate__animated animate__fadeInDown">
      {{displayText}}
    </div>
    <div class="animate__animated animate__fadeInUp center_tips">👆这是线上Allen👆</div>
    <div class="animate__animated animate__fadeInUp show_num" v-show="showNum">🥊:{{ countNum.beat }}｜💗:{{ countNum.love }}</div>
    <!-- <div id="main-rem-input-area" class="animate__animated animate__fadeInUp">
      <input type="text" class="main-rem-input" placeholder="请输入代码并回车">
    </div> -->
  </div>
  <div class="operate_area">
    <div class="oper_common oper_1" v-on:click="countBeat()">🥊胖揍</div>
    <div class="oper_common oper_2" v-on:click="countLove()">抱抱💗</div>
  </div>
  <div id="flyEnum" class="fly_enum">🥊</div>
  <div class="show_our" v-show="showOur">
    <div class="show_our_text">
      <p class="show_our_title">很幸运有你小冰</p>
      <p>这是我们相识的</p>
      <p>第 <span class="our_spec_text">{{ showOurText.month }}</span> 个月</p>
      <p>第 <span class="our_spec_text">{{ showOurText.day }}</span> 天</p>
      <p>第 <span class="our_spec_text">{{ showOurText.hour }}</span> 小时</p>
      <p>第 <span class="our_spec_text">{{ showOurText.min }}</span> 分钟</p>
      <p>第 <span class="our_spec_text">{{ showOurText.sec }}</span> 秒</p>
      <br />
      <p class="bingchat_for_us">Vera&Allen @BingChat</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return {
      isLogin: true,
      displayText: 'Allen',
      showOur: false,
      showMiwen: '54ix5L2g',
      showYiwen: '爱你',
      miwen1: '54ix5L2g',
      miwen2: '5oOz5L2g',
      miwen1Title: '爱你',
      miwen1Text: '我爱你',
      miwen2Title: '想你',
      miwen2Text: '我想你',
      meetTime: 1628687538520,
      calcTime: 1628687538520,
      showOurText: {
        month: 0,
        day: 0,
        hour: 0,
        min: 0,
        sec: 0,
      },

      countNum: {
        beat: 0,
        love: 0,
      },
      showNum: true,
      
      nowScreenWidth: 0,
      nowScreenHeight: 0,
      flyStartX: 0,
      flyStartY: 0,
      flyEndX: 0,
      flyEndY: 0,
      setId: 0,

      timeNum: 0,
    }
  },
  methods: {
    loveu() {
      let nowtime = new Date().getTime();
      let ms = nowtime - this.meetTime;
      let sec = ms/1000
      let min = sec/60
      let hour = min/60
      let day = hour/24
      let month = day/30


      this.showOurText = {
        month: parseInt(month),
        day: parseInt(day),
        hour: parseInt(hour),
        min: parseInt(min),
        sec: parseInt(sec),
      }

      this.showOur = !this.showOur;
      let _that = this;
      setTimeout(() => {
        _that.showOur = !_that.showOur;
      },10000);
    },
    countBeat(){
      this.countNum.beat = this.countNum.beat + 1;
      this.flyAction("🥊");
    },
    countLove(){
      this.countNum.love = this.countNum.love + 1;
      this.flyAction("💗");
    },
    flyAction(innerText){
      // 设置好位置
      this.setFlyPosition();
      // 开始创建元素
      let idStr = "flyEnum" + this.setId;
      this.setId = this.setId+1;
      let flyEnum = document.createElement("div");
      flyEnum.innerText = innerText;
      flyEnum.setAttribute("class","fly_enum");
      flyEnum.setAttribute("id","" +idStr);
      flyEnum.style.top = this.flyStartY+"px";
      flyEnum.style.left = this.flyStartX+"px";
      // flyEnum.setAttribute('top', this.flyStartY+"px");
      // flyEnum.setAttribute('left', this.flyStartX+"px");
      // 添加到当前页面下
      var parentElement = document.getElementById("main-rem");
      parentElement.appendChild(flyEnum);

      // var coreId = document.getElementById("center-dis");
      // eval('var ' + 'timer' + timeNum);
      
      var timer = setInterval(() => {
        let tX = this.flyEndX;
        let tY = this.flyEndY;
        let stepX = 3;
        let stepY = stepX * (this.nowScreenHeight/this.nowScreenWidth)
        console.log("X,Y:"+stepX+","+stepY)
        let isY = this.flyStartY < tY;
        let isX = this.flyStartX < tX;
        // let tempTop = 0;
        // let tempLeft = 0;
        // flyEnum.style.top = flyEnum.offsetTop + step + "px";
        // flyEnum.style.left = flyEnum.offsetLeft + step + "px";
        // flyEnum.style.top = this.flyStartY < tY ? flyEnum.offsetTop + step + "px" : flyEnum.offsetTop - step + "px";
        // flyEnum.style.left = this.flyStartX < tX ? flyEnum.offsetLeft + step + "px" : flyEnum.offsetLeft - step + "px";
        

        if (isY && isX){
          flyEnum.style.top = flyEnum.offsetTop + stepY + "px";
          flyEnum.style.left = flyEnum.offsetLeft + stepX + "px";

          // console.log("flyEnum.offsetTop:" +flyEnum.offsetTop);
          // console.log("flyEnum.offsetLeft:" +flyEnum.offsetLeft);
          // console.log("TY+TX:" +tY+","+tX);
          if (flyEnum.offsetTop >= tY && flyEnum.offsetLeft >= tX){
            flyEnum.style.top = tY;
            flyEnum.style.left = tX;
            clearInterval(timer);
            // let clid = document.createElementbyId(idStr);
            let element = document.getElementById(idStr);
            element.parentNode.removeChild(element);
            return;
          }
        }else if (!isY && isX){
          flyEnum.style.top = flyEnum.offsetTop - stepY + "px";
          flyEnum.style.left = flyEnum.offsetLeft + stepX + "px";
          if (flyEnum.offsetTop <= tY && flyEnum.offsetLeft >= tX){
            flyEnum.style.top = tY;
            flyEnum.style.left = tX;
            clearInterval(timer);
            let element = document.getElementById(idStr);
            element.parentNode.removeChild(element);
            return;
          }
        }else if (isY && !isX){
          flyEnum.style.top = flyEnum.offsetTop + stepY + "px";
          flyEnum.style.left = flyEnum.offsetLeft - stepX + "px";
          if (flyEnum.offsetTop >= tY && flyEnum.offsetLeft <= tX){
            flyEnum.style.top = tY;
            flyEnum.style.left = tX;
            clearInterval(timer);
            let element = document.getElementById(idStr);
            element.parentNode.removeChild(element);
            return;
          }
        }else {
          flyEnum.style.top = flyEnum.offsetTop - stepY + "px";
          flyEnum.style.left = flyEnum.offsetLeft - stepX + "px";
          if (flyEnum.offsetTop <= tY && flyEnum.offsetLeft <= tX){
            flyEnum.style.top = tY;
            flyEnum.style.left = tX;
            clearInterval(timer);
            let element = document.getElementById(idStr);
            element.parentNode.removeChild(element);
            return;
          }
        }
      },5);
    },
    setFlyPosition() {
      this.getScreenData();
      // 上下左右位置随机：上-1，下-2，左-3，右-4
      let positionR = Math.floor(Math.random()*5);
      let randomX = Math.floor(Math.random()*this.nowScreenWidth);
      let randomY = Math.floor(Math.random()*this.nowScreenHeight);
      switch(positionR) {
        case 1:
          this.flyStartX = randomX;
          this.flyStartY = 0;
          break;
        case 2:
          this.flyStartX = randomX;
          this.flyStartY = this.nowScreenHeight;
          break;
        case 3:
          this.flyStartX = 0;
          this.flyStartY = randomY;
          break;
        case 4:
          this.flyStartX = this.nowScreenWidth;
          this.flyStartY = randomY;
          break;
      }
      // this.countNum.beat = this.flyStartX;
      // this.countNum.love = this.flyStartY;
    },
    getScreenData(){
      this.nowScreenWidth = document.documentElement.scrollWidth;
      this.nowScreenHeight = document.documentElement.scrollHeight;
      this.flyEndX = this.nowScreenWidth/2;
      this.flyEndY = this.nowScreenHeight/2;
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
#main-rem {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#center-dis {
  /* border: 1px solid red; */
  font-size: 4em;
  font-weight: bold;
  color: #2c3e50;
  cursor: pointer;
}

.main-rem-input {
  width: 321px;
  height: 50px;
  margin-top: 1em;
  border-radius: 10px;
  text-align: center;
  border: 3px solid #2c3e5091;
  font-size: 1.5em;
  font-weight: 500;
  color: #2c3e50;
  outline: none;
}

.main-rem-input::placeholder {
  font-size: 1em;
  font-weight: 500;
  color: #2c3e505b;
}

.show_our {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .show_our_text {
    white-space: pre-wrap;
    background-color: rgba(240, 242, 245, 0.5);
    backdrop-filter: blur(30px);
    box-shadow: 0 2px 40px rgb(221, 221, 221);
    border-radius: 2em;
    padding-inline: 1em;
    width: 308px;
    height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .show_our_text p {
    margin-block: 9px;
  }
  .show_our_title {
    font-size: 2em;
  }
  .our_spec_text {
    font-size: 1.4em;
    font-weight: 600;
    color: #008B8B;
    /* color: #af0b16be; */
  }
  .bingchat_for_us {
    font-weight: 500;
    color: #838383;
  }

  /* special */
  .center_tips {
    margin-top: 1em;
    font-size: 16px;
    font-weight: 500;
    color: #8b8b8b;
  }

  /* 操作区域 */
  .operate_area {
    /* border: 1px solid red; */
    position: fixed;
    width: 200px;
    height: auto;
    bottom: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .oper_common {
    /* border: 1px solid black; */
    padding-block: 1em;
    padding-inline: 1.5em;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.5s;
    user-select: none;
    box-shadow: 0 0 5px #ccc inset,0 3px 3px #ccc;

  }
  .oper_common:hover {
    /* border: 0; */
    box-shadow: 0 0 5px #ccc inset,0 3px 6px #ccc;
    /* transform: translateY(-5px); */
    transform: scale(1.05);
    transition: all 0.5s;
  }
  .oper_common:active {
    transform: scale(1.2);
    transition: all 0.5s;
  }

  .show_num {
    margin-top: 0.5em;
    font-size: 2em;
    font-weight: 800;
    color: #8b8b8b;
  }
  .fly_enum {
    position:fixed;
    font-size: 3em;
  }
</style>
