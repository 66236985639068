import { createApp } from 'vue';
import ArcoVue from '@arco-design/web-vue';
import App from './beat-allen.vue';
import '@arco-design/web-vue/dist/arco.css';
import 'font-awesome/css/font-awesome.min.css';
import axios from './http';
import animated from 'animate.css'


const app = createApp(App);
app.use(ArcoVue).use(animated);
app.config.globalProperties.$axios = axios;
app.config.productionTip = false;
app.mount('#app');